import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'
import { utils, read, writeFile } from 'xlsx';

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { UserCreate } from '../components/userCreate'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import ShowEventProvider from '../Events/showEventProvider'


import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'


function CreateUser() {
  const currentUser = sessionStorage.getItem('role')
  const supplierId = sessionStorage.getItem('company')

  const [isLoading, setLoading] = useState(false)

  const [show, setShow] = useState(false);

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }
  const auth = sessionStorage.getItem('auth')

  if (auth !== 'true') {
    window.location.href = '/'
  }
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  const [pridedUserList, setPridedUserList] = useState<any[]>([])

  const [downloadList, setDownloadList] = useState<any[]>([])

  const [totalUsers, setTotalUsers] = useState({
    withBiometry: 0,
    withoutBiometry: 0
  })

  const getAllUsers = () => {
    setLoading(true)


    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees}/${supplierId}/true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      }
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setPridedUserList(response.users)

        setDownloadList(response.users.map((item) => {
          return {
            nome: `${item.firstName} ${item.lastName}`,
            cpf: item.document,
            biometria: item.biometry === 0 ? 'Não fez' : 'OK',
            link: `https://onboarding.bepass.com.br/?token=${item.token}`
          }
        }))

        setTimeout(() => {
          console.log(downloadList)
        }, 3000);

        const enabledCount = response.users.filter(item => item.biometric).length
        setTotalUsers({
          withBiometry: enabledCount,
          withoutBiometry: response.length - enabledCount
        })


      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }



  const sendEmail = (data) => {
    setLoading(true)


    fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        name: data.firstName,
        email: data.email,
        token: data.token,
        subject: 'Cadastro para acesso Palmeiras',
        template: 'palmeiras-credenciados'
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        setShow(true)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const inactivateUser = (data) => {

    if (confirm("Confirmar exclusão?") == false) {
      return false
      }
    setLoading(true)


    fetch(`${BASE.api_user.base_url}${BASE.api_user.user_create}/${data.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        status: 'inactive'
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        window.location.reload()
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }




  const downloadTickets = () => {

    const worksheet = utils.json_to_sheet(downloadList);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'relatorio');
    writeFile(workbook, `relatorio.xlsx`);

  }


  const [userNotFound, setUserNorFound] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues
  } = useForm()

  const onSubmit = (data: any) => {
    setUserNorFound(false)

    const search: any = []

    if (data.name) {
      search.push(["firstName", data.name])
    }

    if (data.document) {
      search.push(["document", data.document])
    }

    fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees}/${supplierId}/true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify(search)
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (response.length < 1) {
          setUserNorFound(true)
          setTimeout(() => {
            setUserNorFound(false)
          }, 4000)
        }
        setPridedUserList(response.users)

        setDownloadList(response.users.map((item) => {
          return {
            nome: `${item.firstName} ${item.lastName}`,
            cpf: item.document,
            biometria: item.biometry === 0 ? 'Não fez' : 'OK',
            link: `https://onboarding.bepass.com.br/?token=${item.token}`
          }
        }))

      })
      .catch((error) => {
        setUserNorFound(true)
        setTimeout(() => {
          setUserNorFound(false)
        }, 4000)
      })
  }


  useEffect(() => {
    getAllUsers()
  }, [])


  return (
    <>
      <Row>
        <Col md={6}>
          <MultipleUserCreate />

        </Col>
        <Col md={6}>
          <UserCreate />
        </Col>
      </Row>
      <Row>
        <Col>

          <Card className="Recent-Users">
            <Card.Header>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="align-items-center">
                  <Col sm={12} md={2}>
                    <Card.Title as="h5">Pessoas</Card.Title>
                  </Col>
                  <Col sm={12} md={3}>
                    <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                      Evento
                    </Form.Label>
                    <Form.Control {...register('name')} id="inlineFormInputName" placeholder="Nome do colaborador" />
                  </Col>
                  <Col sm={12} md={3}>
                    {/* <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                      Data
                    </Form.Label> */}
                    <Form.Control {...register('document')} id="inlineFormInputName" placeholder="CPF" />
                  </Col>

                  <Col sm={12} md={3} lg={2} >
                    <Button className='w-100' type="submit">Pesquisar</Button>
                  </Col>
                  <Col>
                    <a href='#' onClick={() => downloadTickets()}>baixar</a>
                  </Col>
                </Row>
              </Form>

            </Card.Header>
            <Card.Body className="px-0 py-2">
              <Table responsive hover>
                <tbody>

                  {pridedUserList &&
                    pridedUserList.map((user: any, index) => (


                      <tr key={index} className="unread" >
                        <td>
                          <img className="rounded-circle" style={{ width: '40px' }} src={
                            user.biometry != 0
                              ? `data:image/png;base64,${user.biometry.croppedImageUrl}`
                              : avatar1
                          } alt="activity-user" />
                        </td>
                        <td>
                          <h6 className="mb-1">{user.firstName} {user.lastName}</h6>
                        </td>
                        <td>
                          <h6 className="mb-1">{user.originToken}</h6>
                        </td>
                        <td>
                          <h6 className="mb-1">{user.document}</h6>
                        </td>
                        <td>
                          <h6 className="mb-1">{user.phoneNumber}</h6>
                        </td>
                        <td>
                          <h6 className="text-muted biometry-status m-t-10">
                            biometria
                            <i className={`fa fa-circle text-c-${user.biometry != 0 ? 'green' : 'red'} f-12 m-l-15`} />
                          </h6>
                        </td>
                        <td>
                          <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                            Enviar e-mail
                          </Link>
                        </td>
                        <td>
                          <Link to="#"  onClick={() => inactivateUser(user)} className="label theme-bg4 text-white f-12">
                            excluir
                          </Link>

                        </td>
                      </tr>

                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

        </Col>
      </Row>
      <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
          <Toast.Body>E-Mail enviado</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default CreateUser
